<template>
    <div>
        <!--  -------------------------------------------------------------------------------- -->
        <!--  BANNERS  -->
        <CoreContainer v-if="bannersResponse?.getItems().length" class="relative">
            <BaseContainerContent :padding="{ bottom: 'large' }">
                <SwiperBanner :banners="bannersResponse!.getItems()" />
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  SHOP BY ROOMS  -->
        <CoreContainer class="bg-basic-200">
            <BaseContainerContent>
                <i18n-t tag="h2"
                        scope="global"
                        class="sim-h3 text-center"
                        keypath="pages.index.shop_by_rooms"
                >
                    <template #br>
                        <br>
                    </template>
                </i18n-t>

                <!--  ROOMS GRID  -->
                <div class="sim-card-grid mt-6 md:mt-12">

                    <!--  ROOM LINK CARD  -->
                    <NuxtLink v-for="(room) in roomsResponse?.getItems()"
                              :key="room.urls!"
                              :to="room.urls ?? ''"
                    >
                        <UiPictureCategoryCard
                            :title="room.name ?? '-'"
                            :image="room.imageUrl"
                        />
                    </NuxtLink>

                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  PRODUCT SHOWCASE  -->
        <CoreContainer>
            <BaseContainerContent :padding="{ top: 'large' }">
                <h2 id="sim-products-showcase" class="sim-h3 text-center">
                    {{ $t('pages.index.products') }}
                </h2>

                <!--  TAB SELECTION  -->
                <!-- TODO
                <UiTabSwitcher
                    v-model="selectedProductShowcaseOption"
                    class="mt-6 justify-center"
                    :options="productShowcaseOptions"
                    aria-labelledby="sim-products-showcase"
                />
-->
            </BaseContainerContent>

            <BaseContainerContent :padding="{ top: 'none', horizontal: 'max-lg:none' }">
                <!--  TAB 1 (best sellers)  -->
                <CoreUiTabContainer :option="productShowcaseOptions[0]!"
                                    :selected-option="selectedProductShowcaseOption"
                >
                    <SwiperCarousel :slides="productsForSlider.length ? productsForSlider : Array.from({ length: 10 }, () => null)" @end-reached="fetchMoreProducts">
                        <template #slide="{ slide: product }">
                            <ProductCard
                                :product="product"
                                :loading="!productsForSlider.length"
                            />
                        </template>
                    </SwiperCarousel>

                </CoreUITabContainer>

                <!--  TAB 2 (top rated)  -->
                <CoreUiTabContainer :option="productShowcaseOptions[1]!"
                                    :selected-option="selectedProductShowcaseOption"
                >
                    <!--  TODO: add top rated products  -->
                </CoreUiTabContainer>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  PRODUCT SET SHOWCASE  -->
        <CoreContainer>
            <BaseContainerContent :padding="{ bottom: 'large' }">

                <LazyProductSetShowcase
                    src="/images/rooms/garden.png"
                    :alt="$t('pages.index.product_set.image_alt')"
                    :popups="productSetPopups"
                >
                    <h3 class="sim-h4">
                        {{ $t('pages.index.product_set.title') }}
                    </h3>

                    <UiButton color="primary" size="giant">
                        {{ $t('pages.index.product_set.button') }}
                    </UiButton>
                </LazyProductSetShowcase>

            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  TOP ARTICLES  -->
        <CoreContainer>
            <BaseContainerContent :padding="{ top: 'none' }">
                <h2 class="sim-h3 text-center">
                    {{ $t('pages.blog.recent_articles') }}
                </h2>
            </BaseContainerContent>

            <BaseContainerContent :padding="{ top: 'none', bottom: 'large' }">
                <section class="sim-blog-grid">
                    <BlogArticleCard v-for="article in topArticles" :key="article.id!" :article="article" />
                </section>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  NEWSLETTER SECTION  -->
        <LazySectionNewsletter />

    </div>
</template>

<script lang="ts" setup>
import type { TabSwitcherOption } from '@core-types/components/CoreUITabSwitcher'
import type { ProductSetPopup } from '../../types/components'
import { CategoryId } from '../assets/ts/enums/model-ids'

definePageMeta({
    layout: 'bare',
})

const { t } = useI18n()
// TODO: use `useTabSwitcher()` instead
const productShowcaseOptions = computed<TabSwitcherOption[]>(() => [
    {
        label: t('pages.index.best_sellers'),
        id: 'sim-product-showcase-tab-best-sellers',
    },
    {
        label: t('pages.index.top_rated'),
        id: 'sim-product-showcase-tab-top-rated',
    },
])
const selectedProductShowcaseOption = ref<TabSwitcherOption>(productShowcaseOptions.value[0]!)

const [
    { data: bannersResponse },
    { data: roomsResponse },
] = await Promise.all([
    useBannersApiService()
        .embed([
            BannerModel.EMBED_MOBILE_IMAGE_URL,
            BannerModel.EMBED_IMAGE_URL,
            BannerModel.EMBED_BANNER_TYPE,
        ])
        .useGet(),
    useCategoriesApiService()
        .whereEquals(CategoryModel.ATTR_PARENT_ID, CategoryId.ROOMS)
        // TODO: add automatic handling of the only parameter to ApiService
        .setParam('only', [
            CategoryModel.ATTR_NAME,
        ])
        .embed([
            CategoryModel.EMBED_URLS,
            CategoryModel.EMBED_IMAGE_URL,
        ])
        .useGet(),
])

// PRODUCTS SLIDER ----------------------------------------------------------------------
// TODO: stop fetching more data when there are no more products
const currentProductsPage = ref<number>(1)
const { items: productsForSlider } = await useProductsApiService()
    .sortByDesc(ProductModel.ATTR_SELLS_COUNT)
    .setPerPage(10)
    .setPage(currentProductsPage)
    .embed([
        ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES,
        ProductModel.EMBED_COMPUTED_TAXED_PRICES,
        ProductModel.EMBED_DISCOUNT_PERCENTS,
        ProductModel.EMBED_DEFAULT_IMAGE_URL,
        ProductModel.EMBED_URLS,
        ProductModel.EMBED_PRODUCT_AVAILABILITY,
        ProductModel.EMBED_ACTIVE_PRODUCT_VARIATIONS_COUNT,
        ProductModel.EMBED_SECONDARY_IMAGE_URL,
    ])
    .useGet({
        server: false,
        lazy: true,
        accumulate: true,
    })

function fetchMoreProducts() {
    currentProductsPage.value++
}
// ------------------------------------------------------------------------------------------

// TOP ARTICLES ----------------------------------------------------------------------
const { items: topArticles } = await useArticlesApiService()
    .setPerPage(3)
    .embed([
        ArticleModel.EMBED_URLS,
        ArticleModel.EMBED_USER,
        ArticleModel.EMBED_IMAGE_URL,
        ArticleModel.EMBED_DEFAULT_ARTICLE_CATEGORY,
        ArticleModel.EMBED_TAGS,
    ])
    .sortByDesc(ArticleModel.ATTR_PUBLISH_AT)
    .useGet()
// ------------------------------------------------------------------------------------------

// TODO: temporary hard-coded data
const productSetPopups = computed<ProductSetPopup[]>(() => [
    {
        badge: t('labels.novelty'),
        heading: t('temp.hp_banner.item_1'),
        subheading: t('temp.hp_banner.price_1'),
        link: t('temp.hp_banner.link_1'),
        position: {
            top: '50%',
            left: '20%',
        },
    },
    {
        badge: t('labels.novelty'),
        heading: t('temp.hp_banner.item_2'),
        subheading: t('temp.hp_banner.price_2'),
        link: t('temp.hp_banner.link_2'),
        position: {
            bottom: '10%',
            left: '35%',
        },
    },
    {
        badge: t('labels.novelty'),
        heading: t('temp.hp_banner.item_3'),
        subheading: t('temp.hp_banner.price_3'),
        link: t('temp.hp_banner.link_3'),
        position: {
            bottom: '20%',
            left: '56%',
        },
    },
])

</script>

<style lang="scss">

.sim-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(8.75rem, 1fr));
    grid-gap: 0.75rem;

    @include more-than(md) {
        grid-template-columns: repeat(auto-fill, minmax(13.75rem, 1fr));
        grid-gap: 2rem;
    }
}

.sim-blog-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    gap: 2.125rem;

    @include more-than(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include more-than(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}

</style>
