<template>
    <slot v-bind="{
        realIndex,
        numberOfSlides,
        slideTo
    }"
    >
        <div class="sim-swiper-pagination" v-bind="$attrs">
            <button v-for="(n, index) in numberOfSlides"
                    :key="index"
                    type="button"
                    :class="buttonClass"
                    :aria-label="`${index + 1}/${numberOfSlides}${realIndex === index ? ' - ' + $t('accessibility.swiper.current_slide') : ''}`"
                    v-bind="parentScope"
                    @click="slideTo(index)"
            >
                <slot name="bullet"
                      v-bind="{
                          isActive: realIndex === index,
                      }"
                >
                    <span class="sim-swiper-pagination__bullet" />
                </slot>
            </button>
        </div>
    </slot>
</template>

<script lang="ts" setup>

const {
    buttonClass,
} = defineProps<{
    buttonClass?: string
}>()

const { injected } = useCoreSwiperWrapperProvide()
if (!injected && import.meta.dev) {
    console.error('[CoreSwiperPagination]: Component must be used within a <CoreSwiperWrapper />')
}

const parentScope = getParentScope()

const numberOfSlides = computed(() => injected?.numberOfSlides.value ?? 0)
const realIndex = computed(() => injected?.activeSlideIndex.value ?? 0)

function slideTo(index: number) {
    // TODO: verify correct usage in case the Swiper doesn't loop
    injected?.swiper.value?.slideToLoop(index)
}

</script>

<style lang="scss" scoped>

.sim-swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

//// TODO: add default styles
//.sim-swiper-pagination__bullet {
//    display: block;
//    width: 1rem;
//    height: 1rem;
//    background-color: red;
//}

</style>
